
// Open header dropdown navigation
// $('.sub-menu-container > a').append('<i class="fa-solid fa-chevron-down"></i>');

let scrollPosition = null;
let $body = $(document.body);

$(document).ready(function () {

    // Deactivate scroll on open menu

    $('.hnav__btn').on('click', function(e){
        
        e.preventDefault();
        preventScrolling();
    });

    // $(".sub-menu-container > a, .sub-menu-container > a > i, .wpml-ls-current-language a").click(function (e) {
    //     if (!$(e.target).is(this)) {
    //         $(e.target).next('.sub-menu').removeClass('slideDown');
    //     }

    //     $(this).next('.sub-menu').toggleClass('slideDown');
    // })
})

$(document).click(function (e) {

    if ($(e.target).is(".hnav__btn") || $(e.target).is(".hnav__btn-span") || $(e.target).is(".deactivate__scroll .header > .row:after")) {

        $(".hnav__btn").toggleClass("active_hnav_btn");
        $(".hnav__menu").toggleClass("active__menu").parent().parent().parent().removeClass("header-active");

    } else if ($(e.target).is(".hnav__menu") || $(e.target).is(".hnav__menu *")) {

        return;

    }else {

        $(".hnav__btn").removeClass("active_hnav_btn");
        $(".hnav__menu").removeClass("active__menu").parent().parent().parent().removeClass("header-active");
        $("body").removeClass("deactivate__scroll").removeAttr('style')
    }

});

$('.menu-item').click(function(){
    if($(document).width() < 1800){
        $(".hnav__btn").removeClass("active_hnav_btn");
        $(".hnav__menu").removeClass("active__menu").parent().parent().parent().removeClass("header-active");
        $("body").removeClass("deactivate__scroll").removeAttr('style')
    }
})


$(window).scroll(function () {
    let scroll = $(window).scrollTop();

    if (scroll >= 0.5) {
        $('.header').addClass('header-scrolled');

    } else {
        $('.header').removeClass('header-scrolled');
    }

})

$('.sub-menu-container > a, .wpml-ls-current-language > a').click(function (e) {
    e.preventDefault();

    // $(this).find('i').toggleClass("fas fa-chevron-down").toggleClass("fas fa-chevron-up")
})


// Prevent Scrolling

const preventScrolling = () => {

        let deactivatedScroll = $body.is(".deactivate__scroll")
        
        if (deactivatedScroll) {
            $body.removeClass("deactivate__scroll").width('');
            window.scrollTo(0, scrollPosition);
            scrollPosition = null;
            $body.removeAttr('style')
            return;
        }else{
            scrollPosition = window.pageYOffset;
            var oldWidth = $body.innerWidth();
            $body.css({ 'top': `-${scrollPosition}px`}).width(oldWidth);
        }
        
        $("body").toggleClass("deactivate__scroll");
}
